import { Message } from "element-ui";
import Vue from "vue";
import Vuex from "vuex";
import { setLocalStorage } from "@/utils/cookie";

const state = {
    access_token: '',
    user_info: {},
};
const actions = {
    showMessage(store, message) {
        Message({
            message: message.data || message.message,
            type: message.type,
            duration: 3000,
        });
    }
};
const mutations = {
    UADATA_ACCESS_TOKEN(state, data) {
        state.access_token = data || '';
        mutations.SAVE_USER_TO_LOCAL(state);

    },
    SAVE_USER_TO_LOCAL(state) {
        setLocalStorage('user', state);
    }
};
const getters = {
    AUTHORIZATION(state) {
        return state.access_token ? 'Bearer ' + state.access_token : '';
    }
};
Vue.use(Vuex);
export default new Vuex.Store({
    state,
    actions,
    mutations,
    getters,
});