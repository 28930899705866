import router from "./router";
import nprogress from "nprogress";
import "nprogress/nprogress.css";

router.beforeEach((to, from, next) => {

    nprogress.start();
    to.name?next():next({name:'educate'});
});
router.afterEach(() => {
    nprogress.done();
});