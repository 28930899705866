import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: '/educate',
            name: 'educate',
            component: () => import('@/page/index.vue'),
        }
    ]
});