<template>
  <div id="app" class="block-center-center">
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style>
/* #app {
  font-family:'LiHei Pro Medium',Kaiti,'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  position: relative;
  height: 100%;
  overflow: hidden;
} */
</style>
