export const Cookie = {
    /**
     * 获取cookie
     * @param {*} name
     */
    get(name) {
        let strCookie = document.cookie;
        let arrCookie = strCookie.split("; ");
        arrCookie.forEach(item => {
            const arr = item.split("=");
            return arr[0] === name ? arr[1] : "";
        });
    },
    set(name, value, expiresDays, domain, path) {
        let cookieString = name + "=" + (value || "");
        let date = new Date();
        domain = domain !== undefined ? ";domain=" + domain : "";
        date.setTime(date.getTime() + (expiresDays || 1) * 24 * 60 * 60 * 1000);
        cookieString = cookieString + domain + "; path=" + (path || "/") + "; expires=" + date.toUTCString();
        document.cookie = cookieString;
    }
};

/**
 * 获取本地信息
 * @param {*} key
 * @returns
 */
export const getLocalStorage = key => {
    if (!key) return;
    const value = window.localStorage.getItem(key);
    return value ? JSON.parse(value) : undefined;
};

export const removeLocalStorage = key => {
    if (!key) return;
    window.localStorage.removeItem(key);
};

export const setLocalStorage = (key, value) => {
    if (!key) return;
    const localValue = typeof value !== 'string' ? JSON.stringify(value) : value;
    window.localStorage.setItem(key, localValue);
};