import axios from "axios";
import $config from "@/config/index";
import { Cookie } from "@/utils/cookie";
import store from "@/store/index";
import qs from 'qs';
// eslint-disable-next-line
if (process.env.NODE_ENV !== 'development') {
    axios.defaults.baseURL = $config.baseURL;
}

axios.defaults.headers['Content-Type'] = 'applicathion/json;charset=UTF-8';
axios.defaults.timeout = 70000;

// 请求拦截器
axios.interceptors.request.use(config => {
    // 每次请求都加token
    config.headers.Authorization = store.getters.AUTHORIZATION;
    config.headers['x-csrf-token'] = Cookie.get('x-csrf-token');
    return config;
}, error => {
    return Promise.reject(error);
});

// 响应拦截器异常处理
axios.interceptors.response.use(response => {
    // 请求成功
    if (response.data.status) {
        return Promise.resolve(response.data);
    } else {
        store.dispatch('showMessage', {
            type: 'error',
            message: response.data.message ? response.data.message : '服务器连接失败',
        });
        return Promise.reject(response);
    }
}, error => {
    if (error && error.response) {
        switch (error.response.status) {
            case 400:
                error.message = '错误请求';
                break;
            case 401:
                error.message = '未授权，请重新登录';
                break;
            case 403:
                error.message = '拒绝访问';
                break;
            case 404:
                error.message = '请求错误,未找到该资源';
                break;
            case 405:
                error.message = '请求方法未允许';
                break;
            case 408:
                error.message = '请求超时';
                break;
            case 500:
                error.message = '服务器端出错';
                break;
            case 501:
                error.message = '网络未实现';
                break;
            case 502:
                error.message = '网络错误';
                break;
            case 503:
                error.message = '服务不可用';
                break;
            case 504:
                error.message = '网络超时';
                break;
            default:
                error.message = `连接错误${error.response.message}`;
        }
    } else {
        error.message = "连接到服务器失败";
    }

    store.dispatch('showMessage', {
        type: 'error',
        data: error.message || error.response.message,
    });
});

export default {
    get(url, param, responseType, header) {
        return axios({
            method: 'get',
            url,
            params: param || {},
            headers: {
                ...(header || {})
            },
            responseType: responseType,
        });
    },
    post(url, param, header) {
        return axios({
            method: 'post',
            url,
            data: param || {},
            headers: {
                ...(header || {}),
                'Content-type': 'application/json;charset=UTF-8'
            }
        });
    },
    postFormData(url, param, header) {
        return axios({
            method: 'post',
            url,
            data: qs.stringify(param) || {},
            headers: {
                ...(header || {}),
                'Content-type': 'application/x-www-form-urlencoded;charset=UTF-8'
            }
        });
    },
    postFile(url, param, header) {
        return axios({
            method: 'post',
            url,
            data: param,
            headers: {
                ...(header || {}),
                'Content-type': 'multipart/form-data',
            }
        });
    },
};