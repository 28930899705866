import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import httpServer from './service/httpServer';
import config from './config';
import ElementUI from 'element-ui';
import "@/common/style/element-variables.less";
import "@/common/style/index.less";
import "@/common/style/element-ui.less";
import "@/common/style/layer.css";

Vue.use(ElementUI);

Vue.prototype.$axios = httpServer;
Vue.prototype.$config = config;
Vue.config.productionTip = false;

import './permission.js';

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
